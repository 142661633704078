<template>
  <div class="timer d-flex flex-column justify-content-center align-items-center flex-fill px-3 py-3">

    <div class="text-muted">You can get tokens in:</div>
    <div class="d-flex justify-content-center align-items-center flex-fill mt-3">
      <div v-for="(item) in timer" :key="item.label" class="d-flex align-items-baseline pl-1">
        <div class="number pr-1">{{ item.value }}</div>
        <div class="text">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Countdown",
  props: {
    time: Number
  },
  data: function () {
    return {
      timer: [],
      labels: ['days', 'hours', 'minutes', 'seconds'],
      currentTime: '',
      endTime: null,
      diff: null,

    }
  },
  methods: {
    startTimer() {
      this.currentTime = moment();
      this.diff = moment.duration(this.endTime.diff(this.currentTime));
      let ind = this.labels.findIndex(label => this.diff.get(label) > 0);
      this.timer = (this.labels.slice(ind)).map(item => {
        return this.getValue(item);
      })
    },
    getValue(label) {
      let time = this.diff.get(label);
      let lab = time !== 1 ? label : label.slice(0, -1);
      time = ('00' + time).slice(-2);
      if (this.isMobile && (label === "minutes" || label === 'seconds')) {
        lab = label.slice(0, 3);
      }
      return {label: lab, value: time};
    }
  },
  mounted: function () {
    this.endTime = moment.unix(parseInt(this.time));
    setInterval(this.startTimer, 1000);
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 460;
    },
  },

}
</script>

<style scoped lang="scss">
  .timer {
    border: 1px solid $color-gray-200;
    border-radius: 10px;
    min-height: 83px;
    @media (max-width: $screen-xs-max) {
      min-height: 74px;
    }

    .time-no-value:first-of-type {
      display: none !important;
    }
  }

  .number {
    font-weight: bold;
    font-size: 28px;
    color: $color-primary;
    width: 43px;
    text-align: right;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
      width: 32px;
    }
  }
</style>
